<template>
    <dialogY :dialogConfig="dialogConfig" @clearDia="clearDia">
        <div slot="dialog">
            <div v-if="formVifList[0]">
                <el-form ref="form" :model="formData" label-width="100px">
                    <el-row type="flex" justify="center">
                        <el-col :span="15">
                            <el-form-item label="产品类型：">
                                <el-select :size="tabsize" v-model="formData.devtype" placeholder="请选择" filterable>
                                    <el-option
                                    v-for="item in dictData"
                                    :key="item.k"
                                    :label="item.v"
                                    :value="item.k">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="8">
                            <el-form-item label="选择文件：">
                                <el-upload
                                    class="upload-demo"
                                    action="#"
                                    list-type="picture"
                                    :show-file-list ='false'
                                    :http-request="uploadImgs1">
                                    <el-button :size="tabsize" type="primary">点击上传</el-button>
                                </el-upload>
                            </el-form-item>
                        </el-col>

                        <el-col :span="7">
                            <div class="text" style="line-height:40px;margin-left:10%;white-space: nowrap;">{{fileName}}</div>
                            <!-- <div class="text" style="line-height:40px;margin-left:10%;white-space: nowrap;overflow:hidden;text-overflow:ellipsis">213124123213124123213124123213124123213124123213124123213124123213124123213124123</div> -->
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="15">
                            <div style="text-align:center;cursor:pointer;margin-top:-5%;margin-bottom:22px;text-decoration:underline;color:blue" @click="downloadFile(1)">下载模板：批量发货模板</div>
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="15">
                            <div style="text-align:center;cursor:pointe">
                                <el-button type="primary" :size="tabsize" @click="submitForm('form')">提交</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div v-if="formVifList[1]">
                <el-form ref="form" :model="formData" label-width="100px">
                    <el-row type="flex" justify="center">
                        <el-col :span="15">
                            <el-form-item label="产品类型：">
                                <el-select :size="tabsize" v-model="formData.devtype" placeholder="请选择" filterable>
                                    <el-option
                                    v-for="item in dictData"
                                    :key="item.k"
                                    :label="item.v"
                                    :value="item.k">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="8">
                            <el-form-item label="选择文件：">
                                <el-upload
                                    class="upload-demo"
                                    action="#"
                                    list-type="picture"
                                    :show-file-list ='false'
                                    :http-request="uploadImgs1">
                                    <el-button :size="tabsize" type="primary">点击上传</el-button>
                                    </el-upload>
                            </el-form-item>
                        </el-col>

                        <el-col :span="7">
                            <div class="text" style="line-height:40px;margin-left:10%;white-space: nowrap;">{{fileName}}</div>
                            <!-- <div class="text" style="line-height:40px;margin-left:10%;white-space: nowrap;overflow:hidden;text-overflow:ellipsis">213124123213124123213124123213124123213124123213124123213124123213124123213124123</div> -->
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="15">
                            <div style="text-align:center;cursor:pointer;margin-top:-5%;margin-bottom:22px;text-decoration:underline;color:blue" @click="downloadFile(2)">下载模板：入库模板</div>
                        </el-col>
                    </el-row>

                    <el-row type="flex" justify="center" >
                        <el-col :span="15">
                            <div style="text-align:center;cursor:pointe">
                                <el-button type="primary" :size="tabsize" @click="submitForm('form')">提交</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </dialogY>
</template>

<script>
    import OSS from "ali-oss";
    import {getBaseUrl} from '../../../requset/http'
    const client = new OSS({ 
        region: "oss-cn-beijing",
        accessKeyId: "LTAIuIJwjQDNloUw",
        accessKeySecret: "GohgvAzqsYA7pTWeceXcftLVy9TMPH",
        bucket: "bwdev"
    });
    import dialogY from './dialogY.vue'
    export default {
        components:{
            dialogY
        },
        props:['width','title','dialogType'],
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
        mounted(){
            this.getDictInfo()
        },
        data(){
            return{
                dialogConfig:{
                    dialogVif:false,
                    width:'',
                    title:'',
                    loading:false,
                },
                oneVif:false, 
                selectDataSource: [],
                selectData:'',
                fileName:'',
                loading:false,
                formVifList:[],
                formData:{
                    devtype:'',
                    file:''
                },
                dictData:[]
            }
        },
        watch:{
            dialogType:{
                handler(newData,oldData){
                    this.formData.devtype = ''
                    this.formData.file = ''
                    this.fileName = ''
                    this.dialogConfig.dialogVif = newData
                    if(!newData){
                        return
                    }
                    this.dialogConfig.loading = !newData
                    this.dialogConfig.width = this.width
                    this.dialogConfig.title = this.title
                    this.formVifList = []
                    this.formVifList[newData-1] = newData
                }
            }
        },
        methods:{
            submitForm(formName){
                this.dialogConfig.loading = true

                let formdata = new FormData()
                formdata.append("file", this.formData.file);
                formdata.append('devtype',this.formData.devtype)

                let url

                if (this.dialogType == 1) {
                    url = this.$api.equipment.uploadDevOut
                } else if(this.dialogType == 2) {
                    url = this.$api.equipment.uploadDevIn
                }

                url(formdata).then(res => {
                    if(res.status == 0){
                        this.$notify.error({
                            title: 'error',
                            message: res.msg,
                            showClose: false,
                            duration: 2000,
                        });
                    }else{
                        this.$notify.success({
                            title: 'success',
                            message: this.title+'成功',
                            showClose: false,
                            duration: 2000,
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally (()=>{
                    this.dialogConfig.dialogVif = false
                    this.$emit('addTableDataOk')
                })
                
            },
            uploadImgs1(file){
                console.log(file);
                this.fileName = file.file.name
                this.formData.file = file.file
            },
            uploadImgs(files) {
                console.log(1);
                const that = this;
                let fileArray = [];
                if (Array.isArray(files)) {
                    fileArray.push(...files);
                } else {
                    fileArray.push(files);
                }
                let resultArray = [];
                let put = async file => {
                    // try {
                        let date = new Date();
                        let yaer = date.getFullYear().toString();
                        let month = (date.getMonth() + 1).toString();
                        let day = date.getDate().toString();
                        let hour = date.getDate().toString();
                        let min = date.getMinutes().toString();
                        let seconds = date.getSeconds().toString();
                        let tmp = yaer + month + day + hour + min + seconds +"/" + file.file.name;
                        console.info(tmp);
                        let result = await client.put(tmp, file.file);
                        if (result.res.status == 200) {
                            that.fileName = result.name
                            console.info( result );
                        }
                    // } catch (error) {
                    //   console.error(error)
                    //   this.$notify({
                    //     title: '警告',
                    //     message: '上传失败',
                    //     type: 'warning'
                    //   });
                    // }
                };
                fileArray.forEach(file => {
                    put(file);
                });
            },
            downloadFile(type){
                type == 1 ? window.open(getBaseUrl()+'/file/设备出库.xls'):
                type == 2 ? window.open(getBaseUrl()+'/file/设备入库.xls'):
                this.$notify.error({
                    title: 'error',
                    message: '未知错误',
                    showClose: false,
                    duration: 2000,
                });
            },
            clearDia(){
                this.$emit('addTableDataOk',-1)
            },
            getDictInfo(){
                this.$api.vipuser.getinfo({
                    dtype:'qd_devtype'
                }).then((res) =>{
                    this.dictData = res.obj
                }).catch((error)=>{
                    console.log(error);
                })
            },
        }
    }
</script>

<style scoped>
    /deep/ .el-dialog__header{
        text-align: center !important;
    }
    /deep/ .el-row{
        height: 100%;
    }
    /deep/ .el-col{
        height: 100%;
    }
    /deep/ .el-upload{
        /* display: flex; */
        /* align-items: center; */
        white-space: nowrap;
    }
</style>