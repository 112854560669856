<!--
  dialog样式 

  - 提交时，打开窗口，更改dialogConfig.dialogVif = true
  - 提交完成 与提交失败时更改为 false
  - 点击（×）关闭时，关闭窗口 更改为false

  //备用
  // clearDia(){
  //     this.dialogConfig.dialogVif = false
  //     this.$emit('addTableDataOk',-1)
  // },
-->
<template>
    <el-dialog :visible.sync="dialogHidden" :width="dialogConfig.width"  :title="dialogConfig.title" :close-on-click-modal="false" :before-close="distoryDia"
    :destroy-on-close="true">
      <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
          <slot name="dialog">
              <!--
                引入组件，
                <template>
                  <dialogY ref="dialog" :dialogConfig="dialogConfig" @clearDia="clearDia">
                        <div slot="dialog">

                            --------------
                               开始表演
                            --------------

                        </div>
                  </dialogY>
                <template>
              -->
          </slot>
      </div>
    </el-dialog>
</template>

<script>
export default {
    /**
     * element-ui --> Dialog
     * 
     * 对象，包含 控制显示（dialogVif） 弹窗宽度（width） 弹窗标题（title） 是否加载中（loading）
     *  
     * dialogConfig:
     * {
     *    dialogVif:true,  
     *    width:'50%',     
     *    title:'弹窗标题', 
     *    loading:true
     * }
     */
    props:['dialogConfig'],
    data(){
      return{
        //子组件不能更改父组件属性， 所以声明一个属性在子组件中
        loading:false,//监听实践的oldData数据异常，这里添加一个loading用于控制加载中状态 
        dialogHidden:false,//记录（控制）dialog显示状态  

      }
    },
    methods:{
      /**
       * 点击右上角(×)时运行的方法
       * @param {*} clearDia  参数为function(){} 调用关闭窗口 return
       */
      distoryDia(clearDia){
        /**
         * 父组件方法
         * 更改dialogVif 为false
         * 调用成功之后就关闭了dialog  所以不需要再调用参数方法 clearDia ↓
         */
        this.$emit('clearDia')

        /**
         * 调用参数方法关闭dialog
         */
        //clearDia()
      }
    },
    watch:{
      dialogConfig:{
          handler(newData,oldData){ 
              /**
               * 判断
               */
              if(newData.loading){
                 this.loading = newData.loading
              }
              if(newData.dialogVif){
                 this.dialogHidden = true
              }else{
                 //当点击×关闭的时候 更改子组件的loading属性为false。作用是--> 减少父组件的代码行数
                 this.loading = false
                 this.dialogHidden = false
              }
          },
          deep:true
      },
    },
}

</script>

<style scoped>
  .max_div{
      width: 100%;
      height: 100%;
  }
  /deep/ .el-dialog__header{
    text-align: center;
  }
</style>
